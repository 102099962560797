// AboutPage.js
import React from 'react';
import './AboutPage.css'; // make sure to create a corresponding CSS file
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import myPicture from './ajpicture.jpg';
import RotatingCube from './RotatingCube';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomePage from './HomePage';

const AboutPage = () => {
    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
      });
      const [cursorVariant, setCursorVariant] = useState("default");

      useEffect(() => {
        const mouseMove = e => {
          setMousePosition({
            x: e.clientX,
            y: e.clientY
          });
        };
    
        window.addEventListener("mousemove", mouseMove);
    
        return () => {
          window.removeEventListener("mousemove", mouseMove);
        };
      }, []);

      const variants = {
        default: {
          x: mousePosition.x - 16,
          y: mousePosition.y - 16,
        },
        text: {
          height: 150,
          width: 150,
          x: mousePosition.x - 75,
          y: mousePosition.y - 75,
          backgroundColor: "white",
          mixBlendMode: "difference"
        },
        smalltext: {
          height: 75,
          width: 75,
          x: mousePosition.x - 45,
          y: mousePosition.y - 45,
          backgroundColor: "white",
          mixBlendMode: "difference"
        }
      }

      const textEnter = () => setCursorVariant("text");
      const smallTextEnter = () => setCursorVariant("smalltext");
      const textLeave = () => setCursorVariant("default");

  return (
    <div className="container">
        <div className="about-container">
        <div className="profile-image-container">
            <img src={myPicture} alt="Your Name" className="profile-image" />
        </div>
        <div className="about-content">
            <h1 onMouseEnter={smallTextEnter} onMouseLeave={textLeave}>About Me 🙂</h1>
            <p onMouseEnter={smallTextEnter} onMouseLeave={textLeave}>
            Im a sophomore Computer Engineering (CS + EE) major at Texas A&M University. Im super interested in New Technology, Artificial Intelligence, and Startups!
            <h3>Some things I've done</h3>
            <ul>
                <li>💻 Interning @Meta Summer 2024</li>
                <li>🤖 Undergraduate Research with the Aggie Autonomous Robotics Research Group</li>
                <li>💻 Interned @Amazon Summer 2023</li>
            </ul>

            <h3>Im Interested in Learning</h3>
            <ul>
                <li>👨🏿‍🔬 How AI and CS can be applied to different fields</li>
                <li>🧬 More in-depth knowledge about a field other than Computer Science</li>
                <li>🗣 Yoruba</li>
            </ul>

            <h3>What Im Reading</h3>
            <ul>
                <li>📖 Why Nations Fail by Daron Acemoglu</li>
                <li>📖 Poor Charlie's Almanack</li>
                <li>📖 The Dream Machine by M. Mitchell Waldrop</li>
            </ul>

            </p>
        </div>
        <motion.div 
            className="cursor"
            variants={variants}
            animate={cursorVariant}
        />
        </div>
    </div>
    
  );
}

export default AboutPage;
