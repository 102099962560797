// RotatingCube.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './RotatingCube.css';

const RotatingCube = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/'); // Redirect to the homepage
  };

  return (
    <div className="cube-container" onClick={handleClick}>
      <div className="cube">
        <div className="face front"></div>
        <div className="face back"></div>
        <div className="face right"></div>
        <div className="face left"></div>
        <div className="face top"></div>
        <div className="face bottom"></div>
      </div>
    </div>
  );
};

export default RotatingCube;
