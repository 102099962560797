import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faFile, faUser } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css'; // Create a CSS file for HomePage if needed
import RotatingCube from './RotatingCube';

function HomePage() {
  // You can move your hooks and functions here if they are only used in HomePage
  // For example:
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  });
  const [cursorVariant, setCursorVariant] = useState("default");

  useEffect(() => {
    const mouseMove = e => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
    },
    text: {
      height: 150,
      width: 150,
      x: mousePosition.x - 75,
      y: mousePosition.y - 75,
      backgroundColor: "white",
      mixBlendMode: "difference"
    },
    smalltext: {
      height: 75,
      width: 75,
      x: mousePosition.x - 45,
      y: mousePosition.y - 45,
      backgroundColor: "white",
      mixBlendMode: "difference"
    }
  }

  const textEnter = () => setCursorVariant("text");
  const smallTextEnter = () => setCursorVariant("smalltext");
  const textLeave = () => setCursorVariant("default");

  return (
    <div className="body">
      <h1 onMouseEnter={textEnter} onMouseLeave={textLeave} className="importantTitle" >
        A.J Bamgbelu
      </h1>
      <p onMouseEnter={smallTextEnter} onMouseLeave={textLeave} className="importantText" >
        Student, Engineer, Learner Of All Things Technology.
      </p>
      <motion.div 
        className="cursor"
        variants={variants}
        animate={cursorVariant}
      />
      <div className="icon-row">
        <a href="https://www.linkedin.com/in/ajbamgbelu/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="icon" />
        </a>
        <a href="https://github.com/8SK3PS8" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} className="icon" />
        </a>
        <a href="https://drive.google.com/file/d/1mj0lG0kaX9EzSTDpbo_b9dqnEJiDHGqw/view?usp=sharing" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFile} className="icon" />
        </a>
        <Link to="/about">
          <FontAwesomeIcon icon={faUser} size="2x" className="icon" />
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
